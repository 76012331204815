<template>
  <div
    class="flex flex-col bg-blue-50 border-1 border-blue-200 shadow-md rounded-xl p-4 md:p-5 dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70"
  >
    <a
      v-if="documentDetails?.referralAgreementAttachment?.id"
      class="mt-1 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-blue-600 decoration-2 hover:text-blue-700 hover:underline focus:underline focus:outline-none focus:text-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-600 dark:focus:text-blue-600"
      href="#"
      @click.prevent="$emit('select-document', documentDetails.referralAgreementAttachment)"
    >
      <DocumentIcon class="h-10" />
      <div v-text="label" />
    </a>
    <span
      v-else
      class="mt-1 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-blue-600 decoration-2 hover:text-blue-700 focus:outline-none focus:text-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-600 dark:focus:text-blue-600"
      v-text="label"
    />
  </div>
</template>
<script>
import { DocumentIcon } from '@/components/icons';

export default {
  name: 'DocumentCard',
  components: {
    DocumentIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    documentDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
