<template>
  <div class="overflow-y-scroll w-full xs:px-8 sm:px-8 md:px-8 lg:px-20">
    <div class="flex-1 py-10 lg:py-12">
      <h1 class="text-3xl font-extrabold text-blue-gray-900">
        Referral Agreements
      </h1>
      <div class="flex flex-wrap py-6 gap-2">
        <DocumentCard
          v-for="item in referralAgreements"
          :key="item.id"
          class="text-left"
          :document-details="item"
          :label="item.label"
          @select-document="openDoc"
        />
        <div v-if="referralAgreements.length === 0">
          There are no agreements available to view at this time.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { downloadFile } from '@/utils';
import {
  DocumentCard,
} from '@/components';

export default {
  name: 'Documents',
  components: {
    DocumentCard,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('agent', ['referralAgreements']),
  },
  async mounted() {
    await this.getAgentReferralAgreements();
  },
  methods: {
    ...mapActions('agent', ['getAgentReferralAgreements']),
    openDoc(doc) {
      downloadFile(doc.id, doc.storageName, doc.name);
    },
  },
};
</script>
