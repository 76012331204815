<template>
  <div>
    <t-alert
      v-if="isSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      Form successfully updated!
    </t-alert>

    <template v-else>
      <spinner v-if="isFetchingFields" />
      <div v-else>
        <div
          v-if="!hideTitle"
          class="text-base font-bold pb-4"
        >
          Provide the information below when the listing goes under contract
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="flex">
            <div class="flex-1 mr-5 pb-5">
              <p>Contract Date</p>
              <c-date-picker
                v-model="$v.contractDate.$model"
                close-on-select
                date-format="Y-m-d"
                placeholder="Contract date"
                class="mt-3"
                :error-message="getDirtyErrorMessage('contractDate')"
              />
            </div>

            <div class="flex-1 pb-5">
              <p>Closing Date</p>
              <c-date-picker
                v-model="$v.closingDate.$model"
                close-on-select
                date-format="Y-m-d"
                placeholder="Closing date"
                class="mt-3"
                :error-message="getDirtyErrorMessage('closingDate')"
              />
            </div>
          </div>

          <div class="pb-5">
            <p>Contract Price</p>
            <c-text-input
              v-model.number="$v.contractPrice.$model"
              type="currency"
              placeholder="Contract Price"
              :error-message="getDirtyErrorMessage('contractPrice')"
            />
          </div>

          <div class="pb-5">
            <p>Commission Reduction Amount</p>
            <c-text-input
              v-model.number="$v.commissionReduction.$model"
              type="currency"
              placeholder="Commission Reduction Amount"
            />
          </div>
          <div
            v-if="exceededCommissionReductionLimit"
            class="bg-red-100 border border-red-500 text-red-700 p-2 mb-4 -mt-2"
          >
            *Commission Reduction Amount limit exceeded. Please call us if you require a higher amount.
          </div>

          <div class="pb-5">
            <p>Transaction Fee Amount</p>
            <c-text-input
              v-model.number="$v.transactionFee.$model"
              type="currency"
              placeholder="Transaction Fee Amount"
              :error-message="getDirtyErrorMessage('transactionFee')"
            />
          </div>

          <div class="pb-5">
            <p>Other Fee Amount</p>
            <c-text-input
              v-model.number="$v.otherFee.$model"
              type="currency"
              placeholder="Other Fee Amount"
              :error-message="getDirtyErrorMessage('otherFee')"
            />
          </div>

          <div class="pb-5">
            <p>Selling Agent Commission Percentage:</p>
            <c-text-input
              v-model.number="$v.listingCommissionPercent.$model"
              type="percentage"
              class="block w-full sm:text-sm rounded-md cursor-not-allowed"
              placeholder="Selling Agent Commission Percentage"
              readonly="readonly"
              :error-message="getDirtyErrorMessage('listingCommissionPercent')"
            />
          </div>

          <div class="pb-5">
            <p>Buying Agent Commission Percentage:</p>
            <c-text-input
              v-model.number="$v.buyerCommissionPercent.$model"
              type="percentage"
              class="block w-full sm:text-sm rounded-md"
              placeholder="Buying Agent Commission Percentage"
              :error-message="getDirtyErrorMessage('buyerCommissionPercent')"
            />
          </div>

          <div class="pb-5">
            <p>Comments</p>
            <c-textarea-input
              v-model="$v.comments.$model"
              :error-message="getDirtyErrorMessage('comments')"
            />
          </div>

          <preliminary-statement-calculator
            :values="statementCalculatorFields"
            :is-loading="isFetchingCalculator"
            class="mb-5"
          />

          <div class="flex mb-5">
            <c-button
              type="submit"
              class="mr-5"
              :is-loading="isSubmitting"
            >
              Save
            </c-button>
            <div
              v-if="exceededCommissionReductionLimit"
              class="bg-red-100 border border-red-500 text-red-700 p-2"
            >
              *Commission Reduction Amount Error
            </div>
            <contract-fell-through
              v-if="shouldDisplayCFT"
              class="mr-5"
              :address="client.address"
              profile-type="property"
              @confirm="clearFields"
            />
            <generate-statement
              v-if="calculateReady"
              :id="client.id"
              :is-seller="true"
              :file-name="statementFileName"
              :query-string="query"
            />
          </div>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators'; // requiredIf
import debounce from 'lodash/debounce';
import {
  CTextInput,
  CDatePicker,
  CTextareaInput,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import PreliminaryStatementCalculator from '@/components/forms/PreliminaryStatementCalculator.vue';
import Spinner from '@/components/Spinner.vue';
import ContractFellThrough from '@/components/ContractFellThrough.vue';
import GenerateStatement from '@/components/GenerateStatement.vue';
import {
  greaterThanZero,
  commissionPercent,
  debounceInterval,
  validDate,
} from '@/constants';
import { formMixin } from '@/mixins';
import {
  directBuyerOptions,
  pcfFields,
  defaultListingCommissionPercent,
} from './constants';

export default {
  name: 'PcfForm',
  components: {
    CTextInput,
    CDatePicker,
    CTextareaInput,
    ContractFellThrough,
    CButton,
    GenerateStatement,
    Spinner,
    PreliminaryStatementCalculator,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    contractDate: null,
    closingDate: null,
    sellerConcession: 0,
    sellerConcessionComments: '',
    transactionFee: 0,
    contractPrice: 0,
    commissionReduction: 0,
    buyerCommissionPercent: 0,
    listingCommissionPercent: defaultListingCommissionPercent,
    otherFee: 0,
    comments: '',
    isSubmitted: false,
    isSubmitting: false,
    isFetchingFields: false,
    isFetchingCalculator: false,
    currentCalculationQuery: '',
    statementCalculatorFields: {
      totalToBrokerage: null,
      referralFee: null,
      referralFeeAmount: null,
    },
    statementFileName: null,
    generateStatementButtonReady: false,
  }),
  validations() {
    return {
      commissionReduction: { required },
      closingDate: { required, validDate },
      comments: { required },
      contractDate: { required, validDate },
      contractPrice: { required, greaterThanZero },
      otherFee: { required },
      sellerConcession: { required },
      transactionFee: { required },
      listingCommissionPercent: { required, commissionPercent },
      buyerCommissionPercent: { required, commissionPercent },
    };
  },
  computed: {
    directBuyerOptions: () => directBuyerOptions,
    fields: () => pcfFields,
    shouldDisplayCFT: vm => vm.client.propertyStatus === 'PENDING',
    query: vm => vm.buildQueryStringParams(vm),
    calculateReady: vm => validDate(vm.contractDate)
      && validDate(vm.closingDate)
      && greaterThanZero(vm.contractPrice)
      && (vm.listingCommissionPercent + vm.buyerCommissionPercent > 0),
    allowConcessionComments: vm => vm.sellerConcession > 0,
    // 0.01 added to avoid seeing error before entry is made
    exceededCommissionReductionLimit: vm => (vm.commissionReduction > ((vm.contractPrice * (vm.listingCommissionPercent / 100)) * 0.05) + 0.01),
  },
  watch: {
    query: debounce(function debounced() {
      this.fetchStatementCalculator();
    }, debounceInterval),
  },
  async mounted() {
    await Promise.all([this.fetchFields(), this.setFileName()]);
  },
  methods: {
    ...mapActions('client', [
      'savePcf',
      'fetchPcf',
      'handleFellThrough',
      'fetchStatementCalculation',
      'getStatementFileNameFromClient',
    ]),
    ...mapActions('agent', ['getAgentDetails']),
    onInput() {
      this.$v.$touch();
    },
    buildQueryStringParams(state) {
      const params = new URLSearchParams({
        contractPrice: state.contractPrice,
        sellerConcessions: state.sellerConcession,
        sellerConcessionComments: state.sellerConcessionComments,
        commissionReduction: state.commissionReduction,
        transactionFee: state.transactionFee,
        otherFee: state.otherFee,
        scheduledClosingDate: state.closingDate,
        buyerCommissionPercent: state.buyerCommissionPercent,
        listingCommissionPercent: state.listingCommissionPercent,
      });
      return params.toString();
    },
    isValid() {
      if (!this.$v.$dirty) this.$v.$touch();
      return !(this.$v.$invalid);
    },
    async setFileName() {
      this.statementFileName = await this.getStatementFileNameFromClient(this.client);
    },
    async handleSubmit() {
      if (this.isValid() && !this.exceededCommissionReductionLimit) {
        const payload = {
          agentId: +this.client.assignedAgent,
          listingId: this.client.propertyReferralId,
          ...this.generatedPayload,
          otherFee: this.otherFee,
          transactionFee: this.transactionFee,
          commissionReduction: this.commissionReduction,
          buyerCommissionPercent: this.buyerCommissionPercent,
          listingCommissionPercent: this.listingCommissionPercent,
        };
        try {
          this.isSubmitting = true;
          await this.savePcf(payload);
          this.$emit('submit');
          this.isSubmitted = true;
          setTimeout(this.getAgentDetails, 3000);
        } catch ({ message }) {
          this.$toast.error(message);
        } finally {
          this.isSubmitting = false;
        }
      }
    },
    async fetchFields() {
      try {
        this.isFetchingFields = true;
        const params = { id: this.client.id };
        const response = await this.fetchPcf(params);
        this.fields.forEach(field => {
          const value = response[field];
          if (!value) return;
          this[field] = value;
        });
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isFetchingFields = false;
      }
    },
    async fetchStatementCalculator() {
      if (this.calculateReady) {
        try {
          this.isFetchingCalculator = true;
          const { id } = this.client;
          const queryString = this.buildQueryStringParams(this);
          this.statementCalculatorFields = await this.fetchStatementCalculation({ id, queryString, isSeller: true });
          this.generateStatementButtonReady = true;
        } catch ({ message }) {
          this.$toast.error(message);
        } finally {
          this.isFetchingCalculator = false;
        }
      }
    },
    clearFields() {
      this.contractDate = null;
      this.closingDate = null;
      this.sellerConcession = '0';
      this.sellerConcessionComments = '';
      this.contractPrice = '0';
      this.comments = '';
      this.commissionReduction = 0;
      this.transactionFee = 0;
      this.otherFee = 0;
      this.generateStatementButtonReady = false;
      this.buyerCommissionPercent = 0;
      this.listingCommissionPercent = defaultListingCommissionPercent;
      this.statementCalculatorFields = {
        totalToBrokerage: null,
        referralFee: null,
        referralFeeAmount: null,
      };
      // Had to use nextTick here because comments were still considered dirty
      this.$nextTick(() => this.$v.$reset());
    },
  },
};
</script>
